<template>
	<div class="notify_page">
		<div class="notify_page_content">
			<div class="page_title">消息中心</div>
			<div class="back_btn" @click="$router.push('/manage/notify')">
				<a-icon type="left" />返回消息列表
			</div>
			<div class="line"></div>
			<p class="notify_title">{{ notify.title }}</p>
			<p class="notify_sendTime">{{ notify.send_time }}</p>
			<div class="notify_content" v-html="notify.msg"></div>
		</div>
	</div>
</template>

<script>
import { messageDetail, readMessage } from "@/api/home.js";
export default {
	data() {
		return {
			checked: false,
			type: "notRead",
			currentPage: 1,
			total: 50,
			pageSize: "10",
			list: [],
			readedList: [],
			notReadList: [],
			read_status: false,
			notReadNum: 0,
			readedNum: 0,
			id: "",
			notify: {},
		};
	},
	mounted() {
		this.id = this.$route.query.id;
		this.getDetail();
	},
	beforeRouteUpdate(to, from, next) {
		this.id = to.query.id;
		this.getDetail();
		next();
	},
	methods: {
		getDetail() {
			messageDetail({ id: this.id }).then(res => {
				if (res.data.code == 200) {
					this.notify = res.data.data;
				}
			});
			readMessage({ id: this.id }).then(res => {
				if (res.data.code == 200) {
					const notReadNum = this.$store.state.notReadNum;
					this.$store.commit("updatenotReadNum", notReadNum - 1);
				}
			});
		},
		changeType(type) {
			this.read_status = type;
			this.list = type ? this.readedList : this.notReadList;
		},
		changePage(page, pageSize) {
			this.getDetail();
		},
	},
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.notify_page {
	width: 1200px;
	min-height: 830px;
	margin: 19px auto 0;
	padding: 40px 120px;
	background-color: #fff;
	.page_title {
		margin-bottom: 28px;
		font-size: 24px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2c354b;
		line-height: 33px;
	}
	.back_btn {
		width: fit-content;
		cursor: pointer;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
	}
	.line {
		margin: 10px auto 31px;
		width: 960px;
		height: 1px;
		background: #dedfe2;
	}
	.notify_title {
		font-size: 22px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #2c354b;
		line-height: 30px;
	}
	.notify_sendTime {
		margin: 8px auto 28px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #878fa7;
		line-height: 20px;
	}
	.notify_content {
		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 24px;
	}
	.tabs {
		margin-top: 30px;
		border-bottom: 1px solid #dedfe2;
		.flex(flex-start);
		.tabs_item {
			width: 32px;
			height: 32px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #878fa7;
			line-height: 32px;
			text-align: center;
			cursor: pointer;
			&.active {
				font-size: 16px;
				font-weight: 500;
				color: #4c84ff;
				border-bottom: 2px solid #4c84ff;
			}
		}
		.clear_notRead {
			width: 76px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 20px;
			cursor: pointer;
			.flex();
		}
	}
	.table {
		margin-top: 15px;
		.table_header {
			padding: 0 88px;
			height: 56px;
			background-color: #ececec;
			.flex();
			.table_header_txt {
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #2c354b;
				line-height: 22px;
			}
		}
		.content_item {
			height: 56px;
			padding: 0 64px 0 20px;
			background-color: #fff;
			border-bottom: 1px solid #dedfe2;
			.flex(flex-start);
			.item_txt {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #374567;
				line-height: 20px;
			}
			.item_read {
				margin-left: 278px;
				color: #4c84ff;
				cursor: pointer;
			}
			.item_title {
				width: 380px;
				margin-right: 10px;
				.ellipsis();
			}
			.item_title::before {
				content: "";
				display: inline-block;
				width: 8px;
				height: 8px;
				margin-right: 6px;
				background: #ff8e43;
				vertical-align: 1px;
				border-radius: 50%;
			}
		}
	}
	.noMessage {
		padding: 100px 340px;
		.title {
			margin-top: 14px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #374567;
			line-height: 20px;
			text-align: center;
		}
	}
	.pagination {
		margin-top: 50px;
		.flex();
		// /deep/ .ant-pagination-item-active {
		//   font-weight: 500;
		//   background: #374567;
		//   color: #fff;
		// }
	}
}
</style>
